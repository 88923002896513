import { Message, MessageBox } from 'element-ui';

let _common = {};

_common = {
    messageError: messageError,
    messageInfo: messageInfo,
    messageSuccess: messageSuccess,
    messageWarning: messageWarning,
    messsageBoxQuestion: messsageBoxQuestion,
    messageBoxSuccess: messageBoxSuccess,
    messageBoxInfo: messageBoxInfo,
    messageBoxError: messageBoxError,
    messageBoxRegister: messageBoxRegister
};

export default _common;

export function messageError(text = '错误') {
    Message({
        message: text,
        type: 'error',
        duration: 2 * 1000
    });
}

export function messageInfo(text = '取消') {
    Message({
        message: text,
        type: 'info',
        duration: 2 * 1000
    });
}

export function messageSuccess(text = '成功') {
    Message({
        message: text,
        type: 'success',
        duration: 2 * 1000
    });
}

export function messageWarning(text = '警告') {
    Message({
        message: text,
        type: 'warning',
        duration: 2 * 1000
    });
}

export function messsageBoxQuestion(text = 'Box询问') {
    return MessageBox.confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    });
}

export function messageBoxSuccess(text = 'Box成功') {
    return MessageBox.confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
    });
}

export function messageBoxInfo(text = 'Box取消') {
    return MessageBox.confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
    });
}

export function messageBoxError(text = 'Box错误') {
    return MessageBox.confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
    });
}
export function messageBoxRegister(text = '已注册提示', confirmCallBack, otherCallBack) {
    return MessageBox.confirm(text, '提示', {
        confirmButtonText: '登录',
        cancelButtonText: '忘记密码',
        type: 'warning',
        distinguishCancelAndClose: true,
        center: true
    }).then(() => {
        confirmCallBack();
    }).catch(
        () => {
            otherCallBack();
        }
    );
}
