<template>
    <div class="help">
        <div class="wrap">
            <div class="service">
                <dl v-for="(cat,index) in articleCats" :key="index">
                    <dt :class="'fore' + index"></dt>
                    <dd>
                        <h3>{{cat.catName}}</h3>
                        <ul class="news-list">
                            <li v-for="article in cat.articles" :key="article.id"
                                    class="article-index lv-1 content-item">
                                <router-link :to="'/article/' + article.id">
                                    {{article.name}}
                                </router-link>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </div>

            <div class="copyright">
                <a href="https://gswj.ebs.org.cn/certification?id=1682275487986601986" target="_blank">
                    <img
                            src="https://szcert.ebs.org.cn/Images/newGovIcon.gif"
                            title="深圳市市场监督管理局企业主体身份公示"
                            alt="深圳市市场监督管理局企业主体身份公示"
                            width="112"
                            height="40"
                            border="0"
                    />
                </a>
                <a href="https://credit.cecdc.com/CX20200513585381577820335.html" target="_blank">
                    <img src="http://icon.szfw.org/cert.png" border="0"/>
                </a>
                <div class="police">
                    <a href="#" target="_blank">
                        <span>
                            <img
                                src="http://ejoy365hk-bucket.oss-cn-shanghai.aliyuncs.com/47/4d/dd/86c01cfe9c52b98e22eabb84ce9f7cb10ff.png?1491375244#h"/>
                        </span>
                    </a>
                    <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">粤公网安备 44030502000623号</a></p>
                </div>
                <div>
                  <a href="https://beian.miit.gov.cn/" type="url" rel="nofollow" target="_blank">粤ICP备 16084320号</a>
                </div>
                <div>深圳市宝安区新安街道龙井社区建安一路9号恒明珠金融大厦东座3601</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                articleCats: []
            };
        },
        async mounted () {
            let api = this.$api;
            this.articleCats = await api.httpGet('api/article/cats?catId=1');
        },
        methods: {
        }
    };
</script>

<style lang="scss" scoped>
    .help {
        border-top: 1px solid #f5f5f5;

        .service {
            overflow: hidden;
            padding: 30px 105px 15px;
            border-bottom: 1px solid #f5f5f5;
            display: flex;
            justify-content: space-between;
            dl {
                position: relative;
                width: 200px;
                float: left;
            }

            dt {
                position: absolute;
                top: 5px;
                left: 0;
                width: 40px;
                height: 40px;
                display: block;
                overflow: hidden;
                background: url("~@/assets/ejoy365pc/images/img_footer1.png") no-repeat;
            }

            dt.fore0 {
                background-position: 0 0;
            }

            dt.fore1 {
                background-position: -50px 0px;
            }

            dt.fore2 {
                background-position: 0 -48px;
            }

            dt.fore3 {
                background-position: -50px -48px;
            }

            dt.fore4 {
                background-position: 0 -95px;
            }

            dd {
                min-height: 120px;
                padding-left: 50px;
                line-height: 20px;

                h3 {
                    font-size: 14px;
                    font-weight: bold;
                    padding: 3px 0;
                    color: #666;
                }
            }
        }
    }

    .copyright {
        margin: 10px 0;
        text-align: center;

        a:nth-child(1) {
            margin-right: 5px;
        }

        .police {
            margin-top: 5px;
        }
    }
</style>

