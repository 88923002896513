const KEY = '__rT_dM__' + (+new Date());
const R = new RegExp('(^|;)\\s*' + KEY + '=1');
const Y1970 = (new Date(0)).toUTCString();

export default {
  // 生成唯一id
  genId (prefix) {
    let idStr = Date.now().toString(36);
    idStr += Math.random().toString(36).substr(3);
    if (prefix) {
      return prefix + idStr;
    } else {
      return idStr;
    }
  },

  // 修改document.domain为顶级域名
  changeDomain () {
    // #ifdef H5
    let domain = document.domain || location.hostname;
    let list = domain.split('.');
    let len = list.length;
    let temp = '';
    let temp2 = '';

    while (len--) {
      temp = list.slice(len).join('.');
      temp2 = KEY + '=1;domain=.' + temp;

      // try to set cookie
      document.cookie = temp2;

      if (R.test(document.cookie)) {
        // clear
        document.cookie = temp2 + ';expires=' + Y1970;

        // 修改domain
        document.domain = temp;
        return;
      }
    }
    // #endif
  },
  checkAddress(address) {
    const regex = /[\u4e00-\u9fa5-_A-z0-9·~～！!@#%…&*（()）,，、。.\\/｛{}｝:：;；’'"“”【】《》?？<>\s]+/;
    let matchArr = address.match(regex);
    if (matchArr === null) {
      return 0;
    }
    let len = 0;
    matchArr.forEach(function (value) { len += value.length; });
    return len;
  },
  checkFullWhitespace(text) {
    let regex = /^[ ]+$/;
    return regex.test(text);
  },
  checkNickName(nickName) {
    const regex = /[\u4e00-\u9fa5A-z0-9-_]+/;
    let matchArr = nickName.match(regex);
    if (matchArr === null) {
      return 0;
    }
    let len = 0;
    matchArr.forEach(function (value) { len += value.length; });
    return len;
  },
  getLoginByCode () {
    // 登录方式-验证码
    return 'LOGIN_TYPE_CODE';
  },
  getLoginByPwd () {
    // 登录方式-密码
    return 'LOGIN_TYPE_PWD';
  },
  // 异步等待,直到表达式不为空
  async waitUntil(fn) {
    return new Promise((resolve) => {
      let timer = setInterval(() => {
        let result = fn();
        if (result) {
          clearInterval(timer);
          resolve(result);
        }
      }, 100);
    });
  }
};
