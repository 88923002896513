<template>
    <div class="header">
        <row1></row1>
        <row2></row2>
        <row3 ref="headerCartNum"></row3>
    </div>
</template>

<script>
    import row1 from './header/row1';
    import row2 from './header/row2';
    import row3 from './header/row3';

    export default {
        components: {
            row1,
            row2,
            row3
        }
    };
</script>
