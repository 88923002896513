export default [
    {
        path: '/user',
        name: 'user',
        components: {
            default: () => import('@/pages/tabbar-user')
        }
    },
    {
        path: '/user/personal',
        name: 'userPersonal',
        component: () => import('@/pages/user-center/account/personal')
    },
    {
        path: '/user/address',
        name: 'userAddress',
        component: () => import('@/pages/user-center/account/address')
    },
    {
        path: '/user/certification',
        name: 'userCertification',
        component: () => import('@/pages/user-center/account/certification')
    },
    {
        path: '/user/security',
        name: 'userSecurity',
        component: () => import('@/pages/user-center/account/security')
    },
    {
        path: '/user/drainage/member',
        name: 'userDrainageMember',
        component: () => import('@/pages/user-center/drainage/member')
    },
    {
        path: '/user/drainage/export',
        name: 'userExport',
        component: () => import('@/pages/user-center/drainage/export')
    },
    {
        path: '/user/drainage/distributor',
        name: 'userDrainageDistributor',
        component: () => import('@/pages/user-center/drainage/distributor')
    },
    {
        path: '/user/drainage/order',
        name: 'userDrainageOrder',
        component: () => import('@/pages/user-center/drainage/order')
    },
    {
        path: '/user/drainage/index',
        name: 'userDrainage',
        component: () => import('@/pages/user-center/drainage/index')
    },
    {
        path: '/user/points',
        name: 'userPoints',
        component: () => import('@/pages/user-center/assets/points')
    },
    {
        path: '/user/memberlottery',
        name: 'userLottery',
        component: () => import('@/pages/user-center/assets/memberlottery')
    },
    {
        path: '/user/coupon',
        name: 'userCoupon',
        component: () => import('@/pages/user-center/assets/coupon')
    },
    {
        path: '/user/attention',
        name: 'userAttention',
        component: () => import('@/pages/user-center/assets/attention')
    },
    {
        path: '/user/exchangecoupon',
        name: 'userExchange',
        component: () => import('@/pages/user-center/assets/exchangecoupon')
    },
    {
        path: '/user/member-balance',
        name: 'memberBalance',
        component: () => import('@/pages/user-center/assets/member-balance')
    },
    {
        path: '/user/experience',
        name: 'userExperience',
        component: () => import('@/pages/user-center/experience')
    },
    {
        path: '/user/couponItemList',
        name: 'userCouponItemList',
        component: () => import('@/pages/user-center/assets/couponItemList/index')
    }
];

