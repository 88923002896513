<template>
  <div class="guide">
    <div class="wrap">
      <div class="one-pic">
        <dl>
          <dt class="service_zhen">真</dt>
          <dd>
            <span>正品保障</span>
            <span>假一赔十</span>
          </dd>
        </dl>
        <dl>
          <dt class="service_yi">宜</dt>
          <dd>
            <span>会员价格</span>
            <span>特惠多多</span>
          </dd>
        </dl>
        <dl>
          <dt class="service_fu">服</dt>
          <dd>
            <span>在线客服</span>
            <span>贴心售后</span>
          </dd>
        </dl>
        <dl>
          <dt class="service_zhuan">赚</dt>
          <dd>
            <span>一键分享</span>
            <span>轻松赚钱</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  lang="scss" scoped>
.guide {
  width: 100%;
  background: #f4f4f4;
  border-bottom: 1px solid #edeeee;
  .one-pic {
    padding: 35px 0;
    display: flex;
    justify-content: space-around;
    dl {
      //display: inline-block;
      // width: 155px;
      //margin-left: 70px;
      dt {
        float: left;
        width: 46px;
        height: 50px;
        background: url("~@/assets/ejoy365pc/images/img.png") no-repeat
          center;
        text-indent: -99999px;
      }
      dt.service_zhen {
        background-position: 0 3px;
      }
      dt.service_yi {
        background-position: 0 -51px;
      }
      dt.service_shui {
        background-position: 0 -105px;
      }
      dt.service_you {
        background-position: 0 -159px;
      }
      dt.service_fu {
        background-position: 0 -214px;
      }
      dt.service_zhuan {
        background-position: 0 -269px;
      }
      dd {
        margin-left: 60px;
        span {
          display: block;
          line-height: 21px;
          font-size: 15px;
          color: #404040;
        }
      }
    }
  }
}
</style>
