import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import store from './store/';

import 'element-ui/lib/theme-chalk/index.css';
import themeTemplate from '@/themes/theme-template';
import 'font-awesome/css/font-awesome.css';
import VDistpicker from 'v-distpicker';
import filter from '@/service/filter';
import api from '@/service/api';
import Share from 'vue-social-share';
import 'vue-social-share/dist/client.css';
import AnalysysAgent from '@/service/analysys-sdk';

if (process.env.NODE_ENV === 'development') {
    window.baseUrl = 'http://localhost:8000/';
} else if (process.env.NODE_ENV === 'temp') {
    window.baseUrl = 'https://aocp.ants-city.com/';
} else if (process.env.NODE_ENV === 'uat') {
    window.baseUrl = 'https://newmall-uat-site.ejoy365hk.com/';
} else if (process.env.NODE_ENV === 'release') {
    window.baseUrl = 'https://newmall-release-site.ejoy365hk.com/';
} else {
    window.baseUrl = 'https://www.ejoy365hk.com/';
}

Vue.config.productionTip = false;

Vue.prototype.$api = api;

window.eventBus = new Vue();// 注册全局事件对象

Vue.use(Share);
Vue.use(ElementUI);
Vue.component('themeTemplate', themeTemplate);
Vue.use(filter);
Vue.component('v-distpicker', VDistpicker);

AnalysysAgent.init({
    appkey: '45ccb6ef4e164b11', // 易观埋点的APPKEY
    uploadURL: 'https://sdk.ejoy365hk.com', // 上传数据的地址，在易观平台数据接入管理-集成
    debugMode: 0 // 0 关闭调试模式(默认), 1 开启调试模式，数据不入库, 2 开启调试模式，数据入库
});
Vue.use(AnalysysAgent);

new Vue({
    router,
    store,
    created() {
        let loginUser = api.getUser();
        if (loginUser) {
            api.ygAliasEvent(loginUser.id + '');
        }
    },
    render: h => h(App)
}).$mount('#app');
