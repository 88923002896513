import Vue from 'vue';
import utils from '@/service/utils';
import http from '@/service/http';
import storage from '@/service/storage';
import router from '@/router';
import message from '@/service/message';
const vue = new Vue();
export default {
  ...utils,
  ...http,
  ...storage,
  ...message,

  // 输出信息,发布环境不输出
  info () {
  },
  // 提示
  toast (message, delay) {
    if (delay) {
      setTimeout(function () {
        vue.$message(message);
      }, delay);
    } else {
      vue.$message(message);
    }
  },
  // 页面跳转
  to (url) {
    if (url) {
      router.push(url);
    } else {
      router.push('*');
    }
  },
  back () {
    if (window.history.length <= 1) {
      this.to('/');
      return false;
    } else {
      router.back(-1);
    }
  },
  // 根据url截取参数
  getUrlParams (url) {
    var params = {};
    if (url.indexOf('?') !== -1) {
      let str = url.substr(url.indexOf('?') + 1);
      let strs = str.split('&');
      for (var i = 0; i < strs.length; i++) {
        params[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
      }
    }
    return params;
  },
  // 根据链接截取url
  getUrl(link) {
    var url = link;
    if (url.indexOf('?') !== -1) {
      let strs = url.split('?');
      url = strs[0];
    }
    return url;
  },
  // 易观埋点事件
  ygTrackEvent(eventId, eventInfo) {
    AnalysysAgent.track(eventId, eventInfo);
  },
  ygAliasEvent(info) {
    AnalysysAgent.alias(info);
  }
};
