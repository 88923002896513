export default [
  {
    path: '/',
    name: 'home',
    components: {
      default: () => import('@/pages/tabbar-home')
    },
    
    meta: {
      // login: true,
      // keepAlive: true
    }
  },
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  },
  {
    path: '/pages/diy',
    name: 'diy',
    components: {
      default: () => import('@/pages/diy')
    }
  },
  // 购物车
  {
    path: '/cart',
    name: 'cart',
    components: {
      default: () => import('@/pages/tabbar-cart')
    }
  },
  // 登陆
  {
    path: '/login',
    name: 'authLogin',
    components: {
      default: () => import('@/pages/auth/login/index')
    }
  },
  // 注册
  {
    path: '/register',
    name: 'authRegister',
    components: {
      default: () => import('@/pages/auth/register/index')
    }
  },
  // 重置密码
  {
    path: '/reset',
    name: 'authReset',
    components: {
      default: () => import('@/pages/auth/reset/index')
    }
  },
  // 绑定手机
  {
    path: '/bind',
    name: 'authBind',
    components: {
      default: () => import('@/pages/auth/bind/index')
    }
  },
  // 订单结算
  {
    path: '/order/checkout',
    name: 'orderCheck',
    components: {
      default: () => import('@/pages/order/checkout')
    }
  },
  // 商品详情
  {
    path: '/goods/details',
    name: 'goodsDetails',
    components: {
      default: () => import('@/pages/goods/details')
    }
  },
  // 商品列表
  {
    path: '/goods/list',
    name: 'goodsList',
    components: {
      default: () => import('@/pages/goods/list')
    }
  },
  // 微信登录
  {
    path: '/auth/wxlogin',
    name: 'authWxLogin',
    components: {
      default: () => import('@/pages/auth/wxLogin')
    }
  },
  // 支付结果页
  {
    path: '/pay/paySuccess',
    name: 'paySuccess',
    components: {
      default: () => import('@/pages/order/pay/success')
    }
  },
  // 去支付页面
  {
    path: '/pay/index',
    name: 'goPay',
    components: {
      default: () => import('@/pages/pay/index')
    }
   
  },
  // 去评论页面
  {
    path: '/order/comment',
    name: 'orderComment',
    components: {
      default: () => import('@/pages/order/comment/index')
    }
  },
  // 底部文章协议
  {
    path: '/article/:id',
    name: 'bottomArticle',
    components: {
      default: () => import('@/pages/article/index')
    }
  },
  // 去活动页
  {
    path: '/page/activity',
    name: 'activity',
    components: {
      default: () => import('@/pages/activity/index')
    }
  },
  // 去活动页
  {
    path: '/pages/activity/index',
    name: 'activity',
    components: {
      default: () => import('@/pages/activity/index')
    }
  },
  // 商品详情
  {
    path: '/pages/item/detail/index',
    name: 'goodsDetails',
    components: {
      default: () => import('@/pages/goods/details')
    }
  },
  // 秒杀商品详情
  {
    path: '/pages/activity/kill/details',
    name: 'activityKillDetails',
    components: {
      default: () => import('@/pages/activity/kill/details')
    }
  },
  // 秒杀商品结算
  {
    path: '/pages/activity/kill/checkout',
    name: 'activityKillCheckout',
    components: {
      default: () => import('@/pages/activity/kill/checkout')
    }
  },
  // 在线客服商品推荐
  {
    path: '/pages/activity/live-service/goods',
    name: 'activityLiveServiceGoods',
    components: {
      default: () => import('@/pages/activity/live-service/goods')
    }
  }
];
