<template>
    <div class="x-widget">
        <component :is="widget.type" :configs="widget.configs"></component>
    </div>
</template>

<script>
    // 通过this.$options获取组件定义信息defines
    // 属性定义中支持的类型有: string,number,image,url,boolean,items
    export default {
        name: 'x-widget',
        components: {
            frameHeader: () => import('./widgets/frame/header/index')
        },
        props: {
            widget: {}
        }
    };
</script>
