import Vue from 'vue';
import Vuex from 'vuex';
import storage from '@/service/storage';
Vue.use(Vuex);
const state = {
  cartNum: storage.getCartNum() // 全局购物车数量
};
export default new Vuex.Store({
  state
});
