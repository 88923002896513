<template>
    <div class="x-function">
        <component :is="type"></component>
    </div>
</template>

<script>
    export default {
        name: 'x-function',
        components: {
            tabbarHome: () => import('./tabbar-home'),
            default: () => import('./default')
        },
        props: {
            type: String
        }
    };
</script>
