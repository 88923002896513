<template>
  <div class="x-function">
    <component :is="type"></component>
  </div>
</template>

<script>
export default {
  name: 'x-function',
  components: {
    tabbarHome: () => import('./tabbar-home'),
    default: () => import('./default'),
    tabbarUser: () => import('./user-center/index'),
    authLogin: () => import('./auth/login'),
    tabbarCart: () => import('./cart/index'),
    authRegister: () => import('./auth/register'),
    orderCheckout: () => import('./order/checkout'),
    orderReturns: () => import('./order/refund/returns'),
    orderRefunds: () => import('./order/refund/refunds'),
    orderDirection: () => import('./order/refund/direction'),
    orderList: () => import('./order/list/index'),
    orderService: () => import('./order/service'),
    accountPersonal: () => import('./user-center/account/personal'),
    accountAddress: () => import('./user-center/account/address'),
    accountCertification: () => import('./user-center/account/certification'),
    accountSecurity: () => import('./user-center/account/security'),
    drainage: () => import('./user-center/drainage/index'),
    drainageMember: () => import('./user-center/drainage/member'),
    drainageExport: () => import('./user-center/drainage/export'),
    drainageOrder: () => import('./user-center/drainage/order'),
    drainageDistributor: () => import('./user-center/drainage/distributor'),
    assetsPoints: () => import('./user-center/assets/points'),
    assetsMemberlottery: () => import('./user-center/assets/memberlottery'),
    assetsExchangecoupon: () => import('./user-center/assets/exchangecoupon'),
    assetsMemberBalance: () => import('./user-center/assets/member-balance'),
    assetsCoupon: () => import('./user-center/assets/coupon'),
    couponItemList: () => import('./user-center/assets/couponItemList/index'),
    assetsAttention: () => import('./user-center/assets/attention'),
    userExperience: () => import('./user-center/experience'),
    goodsDetails: () => import('./goods/details'),
    goodsList: () => import('./goods/list'),
    authReset: () => import('./auth/reset'),
    authBind: () => import('./auth/bind'),
    authWechat: () => import('./auth/wxLogin'),
    orderDetails: () => import('./order/details'),
    payResult: () => import('./order/pay/success'),
    afterList: () => import('./order/list/afterList'),
    goPay: () => import('./order/pay/index'),
    orderComment: () => import('./order/comment/index'),
    bottomArticle: () => import('./article/index'),
    activityIndex: () => import('./activity/index'),
    activityKillDetails: () => import('./activity/kill/details'),
    activityKillCheckout: () => import('./activity/kill/checkout'),
    activityLiveServiceGoods: () => import('./activity/live-service/goods'),
    aftermarketList: () => import('./aftermarket/list/index'),
    aftermarketApplyPage: () => import('./aftermarket/apply-page/index'),
    aftermarketApply: () => import('./aftermarket/apply/index'),
    aftermarketDetailRefunds: () => import('./aftermarket/detail/refunds'),
    aftermarketDetailReturns: () => import('./aftermarket/detail/returns')
  },
  props: {
    type: String
  }
};
</script>
