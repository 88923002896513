<template>
    <div class="theme">
        <ac-pc v-if="theme === 'ac-pc'" :type="type" :widgets="widgets"></ac-pc>
        <ejoy365pc v-if="theme === 'ejoy365pc'" :type="type" :widgets="widgets"></ejoy365pc>
        <updating-pc v-if="theme === 'updating-pc'" :type="type" :widgets="widgets"></updating-pc>
    </div>
</template>

<script>
    import acPc from './ac-pc/template';
    import ejoy365pc from './ejoy365pc/template';
    import updatingPc from './updating-pc/template';
    import api from '@/service/api';

    export default {
        name: 'theme',
        components: {
            acPc,
            ejoy365pc,
            updatingPc
        },
        data () {
            return {
                template: Object,
                theme: String,
                widgets: []
            };
        },
        props: {
            type: String,
            templateId: String
        },
        async created () {
            let activityId = api.getUrl(location.href).split('pc')[1].indexOf('/activity') === -1 ? '' : api.getUrlParams(location.href).id;
            let template = await this.$api.httpGet('/api/cms/pc/template', {
                type: this.type,
                templateId: this.templateId || activityId
            });

            if (template) {
                if (template.title) {
                    document.title = template.title;
                }

                this.template = template;
                this.theme = template.theme;
                this.widgets = template.widgets;
            }
        }
    };
</script>
