import { isNumber } from 'lodash';

export const yuan = value =>
    isNumber(value) ? `¥ ${(value * 100 / 100).toFixed(2)}` : value;

export const money = value =>
    isNumber(value) ? `${(value * 100 / 100).toFixed(2)}` : value;

export default {
    install(Vue) {
        Vue.filter('money', money);
        Vue.filter('yuan', yuan);
    }
};
