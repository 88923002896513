import Vue from 'vue';
import Router from 'vue-router';
import storage from '@/service/storage';

import home from './home';
import user from './user';
import order from './order';
import api from '../service/api';
import addon from '../addon';

Vue.use(Router);

const RouterModel = new Router({
  mode: 'hash',
  base: '/pc/',
  // 路由切换时回到页面顶部
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  routes: [...home, ...user, ...order]
});
const needLoginUrls = [
  '/user',
  '/user/personal',
  '/user/address',
  '/user/certification',
  '/user/security',
  '/user/points',
  '/user/coupon',
  '/user/member-balance',
  '/user/exchangecoupon',
  '/user/memberlottery',
  '/user/attention',
  '/order/list',
  '/order/after/list',
  '/order/checkout',
  '/aftermarket/list/index',
  ...addon.needLoginUrls
];
const loginUrls = [
    '/login',
    '/register'
];

RouterModel.beforeEach((to, from, next) => {
  const user = storage.getUser();
  if (!user) {
    if (to) {
      let path = getPureUrl(to.path);
      if (needLoginUrls.includes(path)) {
        next({ name: 'authLogin', query: { redirect: to.name } });
        storage.setLoginFromPath(path);
      }
    }
    if (to && from && from.path) {
      let fromPath = getPureUrl(from.path);
      let toUrlPath = getPureUrl(to.path);
      // 避免用户在登陆页和注册页来回切换时覆盖缓存
      if (!loginUrls.includes(fromPath) && loginUrls.includes(toUrlPath)) {
        storage.setLoginFromPath(from.fullPath);
      }
    }
    if (from && from.path && !to.path) {
      let path = getPureUrl(from.path);
      if (needLoginUrls.includes(path)) {
        next({ name: 'authLogin', query: { redirect: to.name } });
        storage.setLoginFromPath(path);
      }
    }
  }
  addon.routerFilter(to, from, next);
  // 外链赚客来源
  if (to && to.query && to.query.drp_seller_id) {
    storage.localSet('drpSellerId', to.query.drp_seller_id);
  }
  // 记录站外引流
  markDrainageData(to);
  next();
});
function getPureUrl(url) {
  if (url.indexOf('?') !== -1) {
    return url.split('?')[0];
  }
  return url;
}
// 记录站外引流
function markDrainageData(to) {
  let pages = to.query;
  let user = api.getUser();

  let drainageData = {
    type: pages.source,
    code: null,
    priority: null,
    expiryTime: null
  };
  if (drainageData.type === 'linkstars') {
    drainageData.code = pages.feedback;
    drainageData.priority = 1;
    // 30 days
    drainageData.expiryTime = Date.now() + 1000 * 60 * 60 * 24 * 30;
  } else if (drainageData.type === 'offsite') {
    drainageData.code = pages.code;
    drainageData.priority = 2;
  } else {
    return;
  }
  api.httpPost('api/drainage_record/create', {
    'drainageBaseId': drainageData.code,
    'drainageType': drainageData.type,
    'memberId': !user ? null : user.id
  });
  let drainageDataLocal = api.getDrainageOffsite();
  let result = [];
  if (drainageDataLocal) {
    drainageDataLocal.forEach(e => {
      if (e.type !== drainageData.type) {
        result.push(e);
      }
    });
  }
  result.push(drainageData);
  api.setUpDrainageOffsite(JSON.stringify(result));
}
export default RouterModel;
