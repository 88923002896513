import axios from 'axios';
import storage from './storage';

// 静态数据缓存url,一小时内不会重复请求
let cacheUrls = [
    'api/category/list',
    'api/home/search_keywords',
    'api/home/pc_nav_menus',
    'api/article/'
];

function needCache(url) {
    for (let i = 0; i < cacheUrls.length; i++) {
        let cacheUrl = cacheUrls[i];
        if (url.startsWith(cacheUrl)) {
            return true;
        }
    }
    return false;
}

// create an axios instance
const request = axios.create({
  // baseURL: window.baseUrl, // api 的 base_url
  timeout: 50000 // request timeout
});

// request interceptor
request.interceptors.request.use(
    config => {
        config.headers = storage.getToken();
    return config;
  },
  err => Promise.reject(err)
);

// todo: 加入异常问题处理
// response interceptor
request.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.status === 401) {
        Toast.fail('请登录');
        setTimeout(() => {
            window.location = '#/login/';
        }, 1500);
        return Promise.reject(response);
        // } else if (res.status === 502) {
        //     Toast.fail('网站内部错误，请联系网站维护人员');
        //   return Promise.reject('error');
        // } if (res.status === 401) {
        //   Toast.fail('参数不对');
        //   return Promise.reject('error');
        // } if (res.status === 402) {
        //   Toast.fail('参数值不对');
        //   return Promise.reject('error');
        // } else if (res.status !== 0) {
        //   // 非5xx的错误属于业务错误，留给具体页面处理
        //   return Promise.reject(response);
    } else {
      return response.data;
    }
  }, function (args) {
        let error = args.response.data;
        if (error) {
            if (error.code >= 400 && error.code < 500) {
                // 业务错误
                return Promise.reject(error);
            } else if (error.code >= 500) {
                // 系统错误
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        }
    });

export default {
    // 如果在缓存url中,则请求直接读取本地数据,默认缓存时间一小时
    async httpGet (url, data, slient = false) {
        if (needCache(url) && storage.localGet(url)) {
            return JSON.parse(storage.localGet(url));
        }

        return request({
            url: window.baseUrl + url,
            method: 'GET',
            params: data
        }).then((response) => {
            if (needCache(url)) {
                storage.localSet(url, JSON.stringify(response), 60);
            }
            return Promise.resolve(response);
        });
    },
    async httpPost (url, data, slient = false) {
        return request({
            url: window.baseUrl + url,
            method: 'POST',
            data
        });
    },
    async httpPostCustomizer (url, data, timeout = 5000) {
        return request({
            url: window.baseUrl + url,
            method: 'POST',
            timeout: timeout,
            data
        });
    },
    async httpDelete (url, data, slient = false) {
        return request({
            url: window.baseUrl + url,
            method: 'DELETE',
            data
        });
    }
};
