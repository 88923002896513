<template>
    <div class="x-template ejoy365pc">
        <common-header></common-header>
        <x-function :type="type"></x-function>
        <div v-for="(widget, index) in widgets" v-bind:key="index">
            <x-widget :widget="widget"></x-widget>
        </div>
        <common-footer></common-footer>
    </div>
</template>

<script>
    import xFunction from './function';
    import xWidget from './widget';
    import commonHeader from './common/header';
    import commonFooter from './common/footer';

    // 通过this.$options获取组件定义信息defines
    // 属性定义中支持的类型有: string,number,image,url,boolean,items
    export default {
        name: 'x-template',
        components: {
            xFunction,
            xWidget,
            commonHeader,
            commonFooter
        },
        props: {
            type: String,
            widgets: Array
        }
    };
</script>

<style lang="scss">
    @import 'style.scss';
</style>
