import _ from 'lodash';

const localUserKey = 'LOCAL_USER_KEY';
const localBranchKey = 'LOCAL_BRANCH_KEY';
const headerUserTokenKey = 'headerUserTokenKey';
const headerBranchKey = 'headerBranchKey';
const noLgonLocalCartListKey = 'NO_LOGIN_LOCAL_CART_LIST_KEY_PC';
const localFavoriteListKey = 'LOCAL_FAVORITE_LIST_KEY';
const localLoginFromPathKey = 'LOCAL_LOGIN_FROM_PATH_KEY';
const cartTotalNum = 'CART_TOTAL_NUM';
const localDrainageOffsite = 'LOCAL_DRAINAGE_OFFSITE';

export default {
    // 获取缓存
    localGet (name) {
        let expired = window.localStorage.getItem(`${name}__expires__`) || (Date.now + 1);
        if (Date.now() >= expired) {
            this.localRemove(name);
            return;
        }
        return window.localStorage.getItem(name) || null;
    },
    // 设置缓存,带有效期的(分钟为单位)
    localSet (name, data, expired) {
        if (expired) {
            window.localStorage.setItem(`${name}__expires__`, Date.now() + 1000 * 60 * expired);
        }
        return window.localStorage.setItem(name, data);
    },
    // 删除缓存
    localRemove (name) {
        window.localStorage.removeItem(`${name}__expires__`);
        return window.localStorage.removeItem(name);
    },
    // 用户信息
    getUser() {
        let jsonUser = this.localGet(localUserKey);
        if (jsonUser) {
            let user = JSON.parse(this.localGet(localUserKey));
            if (user && user.expireTime > _.now()) {
                return user;
            }
        }
        return null;
    },
    setUser(user) {
        return this.localSet(localUserKey, JSON.stringify(user));
    },
    removeUser() {
        return this.localRemove(localUserKey);
    },
    getBranchId() {
        let branch = this.localGet(localBranchKey);
        if (branch) {
            return branch.id;
        }
        return null;
    },
    getToken () {
        let result = {};
        if (this.getUser()) {
            result[headerUserTokenKey] = this.getUser().token;
        }
        if (this.getBranchId()) {
            result[headerBranchKey] = this.getBranchId();
        }
        return result;
    },
    setFavoriteList(favoriteList) {
        let map = new Map();
        for (let index in favoriteList) {
            map.set(favoriteList[index].itemId, favoriteList[index]);
        }
        return this.localSet(localFavoriteListKey, JSON.stringify(Array.from(map.entries())));
    },
    getFavoriteList() {
        let favoriteList = this.localGet(localFavoriteListKey);
        if (favoriteList === '{}') {
            return new Map();
        }
        let map = new Map(JSON.parse(favoriteList));
        if (map) {
            return map;
        } else {
            return new Map();
        }
    },
    clickFavorite(item) {
        if (!this.getUser()) {
            return;
        }
        let map = this.getFavoriteList();
        if (map.has(item.itemId)) {
            map.delete(item.itemId);
        } else {
            map.set(item.itemId, item);
        }
        return this.localSet(localFavoriteListKey, JSON.stringify(Array.from(map.entries())));
    },
    addNoLoginCart(shopcart) {
        let cartList = this.getNoLoginCartList();
        let index = cartList.findIndex(cart => cart.skuId === shopcart.skuId && cart.saleBranchId === shopcart.saleBranchId && cart.serviceBranchId === shopcart.serviceBranchId);
        if (index >= 0) {
            cartList[index].qty += shopcart.qty;
            cartList[index].selected = shopcart.selected;
            shopcart = cartList[index];
        } else {
            cartList.push(shopcart);
        }
        this.setNoLoginCartList(cartList);
        return shopcart;
    },
    updateNoLoginCart(shopcart) {
        let cartList = this.getNoLoginCartList();
        let index = cartList.findIndex(cart => cart.skuId === shopcart.skuId && cart.saleBranchId === shopcart.saleBranchId && cart.serviceBranchId === shopcart.serviceBranchId);
        if (index >= 0) {
            cartList[index].qty = shopcart.qty;
            cartList[index].selected = shopcart.selected;
        }
        this.setNoLoginCartList(cartList);
    },
    deleteNoLoginCart(shopcart) {
        let cartList = this.getNoLoginCartList();
        let index = cartList.findIndex(cart => cart.skuId === shopcart.skuId && cart.saleBranchId === shopcart.saleBranchId && cart.serviceBranchId === shopcart.serviceBranchId);
        if (index >= 0) {
            cartList.splice(index, 1);
            this.setNoLoginCartList(cartList);
        }
    },
    deleteAllNoLoginCart() {
        this.setNoLoginCartList([]);
    },
    setNoLoginCartList(cartList) {
        this.localSet(noLgonLocalCartListKey, JSON.stringify(cartList));
    },
    // 未登录情况下购物车数据
    getNoLoginCartList() {
        let cartListStr = this.localGet(noLgonLocalCartListKey);
        let cartList = JSON.parse(cartListStr);
        if (cartList == null || cartList === '') {
            return [];
        }
        return cartList;
    },
    // 跳转登录界面的path
    setLoginFromPath(path) {
        this.localSet(localLoginFromPathKey, path);
    },
    getLoginFromPaht() {
        let path = this.localGet(localLoginFromPathKey);
        if (path === null) {
            path = '*';
        }
        return path;
    },
    getCartNum() {
        let num = this.localGet(cartTotalNum);
        return num || 0;
    },
    setCartNum(num) {
        return this.localSet(cartTotalNum, num);
    },
    setUpDrainageOffsite(data) {
        return this.localSet(localDrainageOffsite, data);
    },
    getDrainageOffsite() {
        let drainageData = this.localGet(localDrainageOffsite);
        let result = [];
        if (drainageData) {
            drainageData = JSON.parse(drainageData);
            for (let i = 0; i < drainageData.length; i++) {
                if (Date.now() < drainageData[i].expiryTime || !drainageData[i].expiryTime) {
                    result.push(drainageData[i]);
                }
            }
        }
        this.setUpDrainageOffsite(JSON.stringify(result));
        return result;
    },
    // 获取站外引流data
    retrieveDrainageData() {
        let drainageDataLocal = this.getDrainageOffsite();
        let priorityMax = 0;
        let drainageData = {};
        if (drainageDataLocal) {
            for (let i = 0; i < drainageDataLocal.length; i++) {
                // 返回优先级最高引流data
                let tempPriority = drainageDataLocal[i].priority;
                if (priorityMax <= tempPriority) {
                    priorityMax = tempPriority;
                    drainageData = drainageDataLocal[i];
                }
            }
        }
        return drainageData;
    },
    // 根据type删除站外引流data
    updateDrainageOffsite(type) {
        let drainageDataLocal = this.getDrainageOffsite();
        let result = [];
        if (drainageDataLocal) {
            for (let i = 0; i < drainageDataLocal.length; i++) {
                if (drainageDataLocal[i].type !== type) {
                    result.push(drainageDataLocal[i]);
                }
            }
        }
        this.setUpDrainageOffsite(JSON.stringify(result));
    }
};
