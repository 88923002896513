export default [
    // 退货退款
    {
      path: '/order/returns',
      name: 'orderReturns',
      components: {
        default: () => import('@/pages/order/refund/returns')
      }
    },
    // 退款
    {
      path: '/order/refunds',
      name: 'orderRefunds',
      components: {
        default: () => import('@/pages/order/refund/refunds')
      }
    },
    // 退款去向
    {
      path: '/order/direction',
      name: 'direction',
      components: {
        default: () => import('@/pages/order/refund/direction')
      }
    },
    // 售后
    {
      path: '/order/service',
      name: 'orderService',
      components: {
        default: () => import('@/pages/order/service')
      }
    },
    // 订单详情
    {
      path: '/order/details',
      name: 'orderDetails',
      components: {
        default: () => import('@/pages/order/details')
      }
    },
    // 订单列表
    {
      path: '/order/list',
      name: 'orderList',
      components: {
        default: () => import('@/pages/order/list/index')
      }
    },
    // 售后订单列表
    {
      path: '/order/after/list',
      name: 'orderAfterList',
      components: {
        default: () => import('@/pages/order/list/afterList')
      }
    },

    {
        path: '/aftermarket/list/index',
        name: 'aftermarketList',
        components: {
            default: () => import('@/pages/aftermarket/list/index')
        }
    },
    {
        path: '/aftermarket/apply-page/index',
        name: 'aftermarketApplyPage',
        components: {
            default: () => import('@/pages/aftermarket/apply-page/index')
        }
    },
    {
        path: '/aftermarket/apply/index',
        name: 'aftermarketApply',
        components: {
            default: () => import('@/pages/aftermarket/apply/index')
        }
    },
    {
        path: '/aftermarket/detail/refunds',
        name: 'aftermarketDetailRefunds',
        components: {
            default: () => import('@/pages/aftermarket/detail/refunds')
        }
    },
    {
        path: '/aftermarket/detail/returns',
        name: 'aftermarketDetailReturns',
        components: {
            default: () => import('@/pages/aftermarket/detail/returns')
        }
    }
  ];
