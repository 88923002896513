<template>
    <div class="header-row1">
        <div class="wrap">
            <el-row :gutter="20" type="flex" justify="space-between">
                <el-col :span="8" class="left">
                    <p v-if="!getUser()">欢迎光临易捷国际网上商城</p>
                    <p class="name" v-if="getUser()">
                      您好，{{getNickName()}}
                           <span slot="reference" @click="clickLogout"> 退出登录 </span>
                    </p>
                    <router-link  v-if="!getUser()" to="/login">
                        <p class="name" style="cursor:pointer">去登录</p>
                    </router-link>
                    <router-link v-if="!getUser()"  to="/register">
                      <p class="name" style="cursor:pointer">去注册</p>
                    </router-link>

                </el-col>
                <el-col :span="11" class="right">
                    <p class="fr-content">
                        <a href="https://app.singlewindow.cn/cas/login?_loginCeb=1&service=https%3A%2F%2Fcustoms.chinaport.gov.cn%2Fdeskserver%2Fcus%2FdeskIndex%3Fmenu_id%3Dswcebpub">跨境电子商务年度个人额度查询</a>
                    </p>
                    <p class="fr-content">
                        <router-link to="/order/list">我的订单</router-link>
                    </p>
                    <P class="fr-content">
                        <router-link to="/user">会员中心</router-link>
                    </P>
                    <div class="fr-content fr-qrcode">
                        <div class="qrcode">
                            <a href="#">
                                <span class="qr"></span>
                                <span>易捷国际微商城</span>
                            </a>

                        </div>
                        <div class="menu-down qrcode-show">
                          <span class="arrow">
                            <i class="layer1"></i>
                            <i class="layer2"></i>
                          </span>
                        </div>
                    </div>
                    <div class="fr-content app">
                        <p>
                            <a href="#"> 手机App</a>
                        </p>
                        <div class="menu-down qrcode-show">
                          <span class="arrow">
                            <i class="layer1"></i>
                            <i class="layer2"></i>
                          </span>
                        </div>
                    </div>
                    <div class="fr-content service">
                        <p>
                            <a href="#"> 客户服务</a>
                        </p>
                        <div class="outline"></div>
                        <div class="blank"></div>
                        <div class="menu-down">
                            <div>
                                <a id="kf_info" href="javascript:void(0)" @click="navToLiveService" class="minicart-text">联系客服</a>
                            </div>
                            <div>(工作日 上班时间： 9:00-22:00;)</div>
                            <div>(午休：12:30-13:30;)</div>
                            <div>(晚餐：18:00-19:00;)</div>
                            <div>
                                <a href="#">电邮地址:service@ejoy365hk.com</a>
                            </div>
                            <div>
                                <a href="#" title="帮助中心" target="_blank">帮助中心</a>
                            </div>
                        </div>
                        <i class="arrow1"></i>
                    </div>
                    <p class="fr-content">
                        <a href="#" v-on:click="clickOwnSite()">收藏本站</a>
                    </p>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import api from '@/service/api';
    import storage from '@/service/storage';
    export default {
        data() {
            return {
                user: {},
                logoutDialogVisible: false
            };
        },
        created() {
            let me = this;
            eventBus.$on('update-userinfo', function (data) {
                let user = api.getUser();
                user.nickname = data;
                api.setUser(user);
                me.$router.go(0);
            });
        },
        mounted() {
            this.user = api.getUser();
        },
        methods: {
            async navToLiveService() {
                let url = await api.httpGet('api/live-service/get_url', {
                    param: 'member'
                });
                window.open(url, '_blank');
            },
            getNickName() {
                let user = api.getUser();
                let nickName = user.nickname;
                return nickName;
            },
            getUser() {
                let user = api.getUser();
                if (user && user.nickname) {
                    return true;
                } else {
                    return false;
                }
            },
            exitLogin() {
                // 更新全局购物车数量
                api.setCartNum(0);
                this.$store.state.cartNum = 0;
                window.localStorage.removeItem('OldKeys');
                storage.removeUser();
                storage.setFavoriteList([]);
                this.user = api.getUser();
                this.$router.go(0);
            },

            clickOwnSite() {
                alert('请使用Ctrl+D加入收藏');
            },
            clickLogout() {
                this.$confirm('确定要退出登录吗？', '退出登录', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    center: true,
                    type: 'warning'
                }).then(() => {
                    this.$notify({
                        title: '成功',
                        message: '退出成功',
                        type: 'success',
                        position: 'top-left'
                    });
                    let me = this;
                    setTimeout(function () {
                        me.exitLogin();
                    }, 1000);
                }).catch((e) => {
                    console.error(e);
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header-row1 {
        width: 100%;
        background: #f1f1f1;
        border-bottom: 1px solid #eee;
        height: 30px;
        line-height: 30px;

        .wrap {
            .left {
               //border: 1px solid #ff0000;
               width:473px;
                p {
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 12px;
                }
                .name {
                    margin-left: 20px;
                    position: relative;
                    cursor: Auto;
                    &:before {
                        content: "";
                        position: absolute;
                        left: -20px;
                        top: 10px;
                        background: #DDDDDD;
                        width: 1px;
                        height: 12px;
                    }
                    span:hover{
                      color: #f39800;
                      cursor: pointer;
                    }
                }
            }

            .right {
                width: 61%;
                //border: 1px solid #ff0000;
                .fr-content {
                    display: inline-block;
                    margin: 0 10px;
                    font-size: 12px;
                    position: relative;

                    a {
                        font-size: 12px;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: -10px;
                        top: 10px;
                        background: #ddd;
                        width: 1px;
                        height: 12px;
                    }

                    &:first-child:before {
                        width: 0;
                    }

                    &:hover {
                        color: #f39800;
                    }

                    .qrcode {
                        padding-left: 20px;

                        .qr {
                            position: absolute;
                            top: 9px;
                            left: 0;
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            background: url("https://image.ejoy365hk.com/attachment/a000b1df90f519c8c816bab1d815a4b3.png") no-repeat;
                        }
                    }
                }

                .menu-down .arrow i {
                    position: absolute;
                    left: 50%;
                    top: -16px;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    overflow: hidden;
                    border-width: 8px;
                    border-style: dashed dashed solid;
                }

                .menu-down .arrow i.layer2 {
                    border-color: transparent transparent #fff;
                }

                .menu-down .arrow i.layer1 {
                    border-color: transparent transparent #333;
                    top: -16px;
                }

                .qrcode-show {
                    width: 116px;
                    height: 129px;
                    border: 1px solid #ddd;
                    display: none;
                    position: absolute;
                    top: 40px;
                    left: 0;
                    z-index: 10;
                }

                .fr-qrcode:hover .qrcode-show {
                    display: flex;
                    -moz-background-size :contain;
                    -webkit-background-size :contain;
                    -o-background-size :contain;
                    background-size :contain;
                    background: url("https://image.ejoy365hk.com/attachment/a000b1df90f519c8c816bab1d815a4b3.png") no-repeat 0 -30px #fff;
                }

                .app:hover .qrcode-show {
                    display: block;
                    background: url("https://image.ejoy365hk.com/attachment/a000b1df90f519c8c816bab1d815a4b3.png") no-repeat 0 -30px #fff;
                    left: -30px;
                }

                .service {
                    padding-right: 20px;

                    .menu-down {
                        position: absolute;
                        left: 0;
                        z-index: 10;
                        color: #000;
                        border: 1px solid #ddd;
                        background: #fff;
                        display: none;
                        div {
                            line-height: normal;
                            width: 235px;
                            text-align: left;
                            overflow: hidden;
                            padding: 5px 0 0 10px;
                        }
                    }

                    &:hover .menu-down {
                        display: block;
                    }

                    .arrow1 {
                        position: absolute;
                        right: 8px;
                        top: 12px;
                        display: inline-block;
                        border-left: 1px solid #818181;
                        border-bottom: 1px solid #818181;
                        width: 5px;
                        height: 5px;
                        transform: rotate(-45deg);
                        transition: transform 0.2s ease-in 0s;
                    }

                    &:hover .arrow1 {
                        transform: rotate(135deg);
                        top: 14px;
                    }

                    /*.outline{*/
                    /*display:none;*/
                    /*}*/
                    &:hover .outline {
                        /*display: block;*/
                        position: absolute;
                        border: 1px solid #ddd;
                        background: #fff;
                        z-index: -1;
                        left: -9px;
                        top: 3px;
                        width: 95px;
                        height: 100%;
                    }

                    &:hover .blank {
                        position: absolute;
                        z-index: 11;
                        top: 30px;
                        left: -9px;
                        width: 85px;
                        height: 2px;
                        overflow: hidden;
                        /*background: #fff;*/
                    }
                }
            }
        }
    }
</style>
