<template>
    <div class="x-widget">
        <component :is="widget.type" :configs="widget.configs"></component>
    </div>
</template>

<script>
    // 通过this.$options获取组件定义信息defines
    // 属性定义中支持的类型有: string,number,image,url,boolean,items
    export default {
        name: 'x-widget',
        components: {
            adOnepic: () => import('./widgets/adverts/onepic/index'),
            adCarousel: () => import('./widgets/adverts/carousel/index'),
            adItemList: () => import('./widgets/adverts/item-list/index'),
            adPicWall: () => import('./widgets/adverts/pic-wall/index'),
            adFloor: () => import('./widgets/adverts/floor/index'),
            adRecommend: () => import('./widgets/adverts/recommend/index'),
            adGoodsRanklist: () => import('./widgets/adverts/goods-ranklist/index'), // 商品排行榜
            adLeftNavigationBar: () => import('./widgets/adverts/left-navigation-bar/index'),
            adRedPacketRain: () => import('./widgets/adverts/red-packet-rain/index'),
            adHomePageCover: () => import('./widgets/adverts/homepage-boot-activity/index'),
            ejoysSmImg: () => import('./widgets/adverts/sec-sm-img/index'),
            activityGoodsSale: () => import('./widgets/adverts/activity-goods-sale/index'),
            customHtml: () => import('./widgets/adverts/custom-html/index'),
            adActivityKill: () => import('./widgets/adverts/activity-kill/index'),
            adRichText: () => import('./widgets/adverts/rich-text/index'),
            revisionActivePage: () => import('./widgets/adverts/revision-active-page/index'),
            getCoupon: () => import('./widgets/adverts/get-coupon/index')
        },
        props: {
            widget: {}
        }
    };
</script>
