<template>
  <!-- <div :class="['header-row2', 'wrap',{'row2-fixed':isFixed}]"> -->
    <div :class="['header-row2', 'wrap']">
    <el-row>


      <el-col :span="8">
        <div class="logo">
          <a href="/pc" >
            <img
              src="https://image.ejoy365hk.com/attachment/0fec1a0e6d021b14c4b4a8b36d8f0c3c.png"
              class="default_logo"
              alt
            />
            <img
              src="https://image.ejoy365hk.com/a3/52/3f/a59bc471cc87bf648e81b8c1a491026cac1.png"
              class="assistant_logo"
              alt
            />
          </a>
        </div>
      </el-col>

      <el-col :span="13">
        <div class="search">
          <div class="searchbar">

            <div class="search-box">
              <input type="text" v-model="input"
                     @mouseover="showInput"
                     @mouseleave="showInputflus"
                     data-autocompleter
                     @keyup.enter="doSearch(input)"
                     :placeholder="hotSearchTerms"/>

              <ul class="category-box"
                  @mouseover="showInput"
                  @mouseleave="showInputflus"
                  :style="{visibility:expandCategory?'visible':'hidden'}">

                  <div class="keyword">
                      <div  v-if="isShow" class="hots">
                          <div style="margin: 5px 0 10px 0;">最近搜索
                              <li style="width: 15px;
                                height: 15px;
                                right: 10px;
                                position: absolute;
                                background: white !important;
                                ">

                                  <img @click="oldDelete"
                                          src="https://ejoy365hk-bucket.oss-cn-shanghai.aliyuncs.com/aocp/images/delIcon.png"
                                  />
                              </li>

                          </div>

                          <li v-for="(item,index) in oldKeywordList" :key="index">
                              <router-link @click.native="doSearch(item)" :to="'/goods/list?words=' + item + '&searchType=关键词搜索'">
                                  {{item}}
                              </router-link>
                          </li>
                      </div>
                      <div class="hots">
                          <ul>
                              <div style="margin: 5px 0 10px 0;">热搜推荐</div>
                              <li v-for="(item,index) in hotKeywordList" :key="index">
                                  <router-link @click.native="doSearch(item)" :to="'/goods/list?words=' + item + '&searchType=关键词搜索'">
                                      {{item}}
                                  </router-link>
                              </li>
                          </ul>
                      </div>
                  </div>

              </ul>


              <button type="submit" @click="doSearch(input)" class="btn btn-search">搜索</button>
            </div>

            <div class="hots">
              <ul>
                <li>热门品牌：</li>
                <li v-for="(item,index) in hotKeywordList" :key="index">
                  <router-link @click.native="doSearch(item)" :to="'/goods/list?words=' + item + '&searchType=热门搜索'">{{item}}</router-link>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </el-col>


      <el-col :span="3">
        <router-link :to="{path:'/cart'}">
            <div class="minicart">
                <span class="minicart-text">我的购物车</span>
                <div class="img">
                <img class="img-before" src="@/assets/ejoy365pc/images/row2-cart.png" alt="">
                <img class="img-after" src="@/assets/ejoy365pc/images/row2-cart1.png" alt="">
                <span class="shopping clamp">{{totalCartNum}}</span>
                </div>
            </div>
        </router-link>
      </el-col>


    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import bus from '@/service/bus.js';
import storage from '@/service/storage';
// import api from '@/service/api';
export default {
  data() {
    return {
    //   keyword: null,
      oldKeywordList: [],
      oldKeywordList2: [],
      isShow: false,
      defaultKeyword: '雅诗兰黛',

      expandCategory: false,
      input: '',
      hotKeywordList: [],
      searchScrollTop: '',
      isFixed: false,
      hotSearchTerms: ''
    };
  },
  created() {
    this.init();
    bus.$on('searchScrollTop', (data) => {
      this.searchScrollTop = data;
    });
  },
  async mounted() {
    // 热词推荐
    let hotKeywords = await this.$api.httpGet('api/home/search_keywords');
    this.hotKeywordList = hotKeywords.split(',');
    // 滚动
    window.addEventListener('scroll', this.navScroll, true);
    // 加载热搜词
    this.hotSearchTerms = this.$route.query.words ? this.$route.query.words : '雅诗兰黛';
  },
  computed: {
    ...mapState(['cartNum']),
    // 计算数量
    totalCartNum () {
      return this.cartNum;
    }
  },
  methods: {
    async init () {
        // 获取用户信息
        let user = storage.getUser();
        if (user) {
            let userId = window.localStorage.getItem('userId');
            // 如果发现登录的用户是不同账号，就清除浏览记录
            if (userId !== user.id.toString()) {
                this.oldKeywordList = [];
                window.localStorage.removeItem('OldKeys');
            }
            window.localStorage.setItem('userId', user.id);
        }

        let expired = window.localStorage.getItem('OldKeys');
        if (expired) {
            this.isShow = true;
        }
        var OldKeys = JSON.parse(expired);
        this.oldKeywordList = OldKeys;
    },
    async saveKeyword(keyword) {
          let expired = window.localStorage.getItem('OldKeys');
          if (expired == null) {
              expired = '[]';
              var OldKeys = JSON.parse(expired);
          } else {
              var OldKeys = JSON.parse(expired);
          }

          var findIndex = OldKeys.indexOf(keyword);
          if (findIndex === -1) {
            OldKeys.unshift(keyword);
          } else {
            OldKeys.splice(findIndex, 1);
            OldKeys.unshift(keyword);
          }
          // 最多10个纪录
          if (OldKeys.length < 10) {
              window.localStorage.setItem('OldKeys', JSON.stringify(OldKeys));
          } else {
              OldKeys.splice(OldKeys.length - 1, 1);
              window.localStorage.setItem('OldKeys', JSON.stringify(OldKeys));
          }
        this.oldKeywordList = OldKeys; // 更新历史搜索
    },
    // 清除历史搜索
    oldDelete() {
        this.$confirm('确定要清除历史搜索吗？', '清除历史搜索', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true,
            type: 'warning'
        }).then(() => {
            this.oldKeywordList = [];
            window.localStorage.removeItem('OldKeys');
            this.isShow = false;
            this.$notify({
                title: '成功',
                message: '清除成功',
                type: 'success',
                position: 'top-left'
            });
        });
    },
    showInput() {
      this.expandCategory = true;
    },
      showInputflus() {
          this.expandCategory = false;
      },
    // 执行搜索
    doSearch(keyword) {
        if (keyword) {
            this.hotSearchTerms = keyword;
        }
      // 按钮点击
      this.input = this.input ? this.input : this.hotSearchTerms;
      this.saveKeyword(this.input); // 保存为历史
      if (!this.input) {
        if (this.$route.path === '/') {
          location.reload();
        } else {
          this.$router.push({
            path: './pc'
          }).catch(err => err);
          return;
        }
      }
      if (this.$route.path === '/goods/list') {
        this.$router.push({
          path: '/goods/list',
          query: {
            words: this.input,
            searchType: '关键词搜索'
          }
        }).catch(err => err);
      } else {
        this.$router.push({
          path: '/goods/list',
          query: {
            words: this.input,
            searchType: '关键词搜索'
          }
        }).catch(err => err);
      }
      this.input = '';
      this.init();
    },

    debounce(fn, time) {
      let timeout = null;
      return function () {
          if (timeout) {
              clearTimeout(timeout);
          }
          timeout = setTimeout(fn, time);
      };
    },
    navScroll(e) {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > this.searchScrollTop) {
            this.isFixed = true;
        } else {
            this.isFixed = false;
        }
    }
  },
   destroyed() {
      window.removeEventListener('scroll', this.navScroll);
  }
};
</script>

<style lang="scss" scoped>
.header-row2 {
  padding: 30px 0 20px 0;
  overflow: hidden;

  .logo {
    width: 440px;

    .default_logo {
      width: 200px;
    }

    .assistant_logo {
      margin-top: 6px;
      margin-left: 36px;
    }
  }

  .search {
    width: 550px;
    height: 60px;
    margin: 8px 0 0 60px;
    overflow: hidden;

    .searchbar {
      display: block;
      height: auto;

      .search-box {
        height: 38px;

        input {
          width: 461px;
          float: left;
          background-color: #fff;
          border: 2px solid $ejoy365pc-base-color;
          color: #999;
          height: 100%;
          padding: 0 5px;
        }

        .keyword {
          width: 461px;
          float: left;
          background-color: #fff;
          border: 0px solid rgba(0, 0, 0, 0.3);
          color: black;
          height: 100%;
          padding: 0 5px;
            .hots {
                font-size: 14px;
                font-weight: 500;
                color: #000;
                overflow: hidden;
                padding-top: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    position:absolute;
                }
                li {
                    display: inline-block;
                    background: #f5f5f5;
                    padding: 1px 8px;
                    text-align: center;
                    margin-right: 10px;
                    margin-left: 10px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    a {
                        font-size: 14px;
                        font-weight: 500;
                        color: #000;
                        margin-left: 0px;
                        &:hover{
                            color:$ejoy365pc-base-color;
                        }
                    }
                }
            }
        }

        button {
          background: $ejoy365pc-base-color;
          color: #fff;
          width: 88px;
          height: 100%;
          font-size: 14px;
          text-align: center;
          border: 0 none;
        }
      }

      .hots {
        overflow: hidden;
        padding-top: 5px;

        li {
          display: inline-block;

          a {
            color: #999;
            margin-left: 10px;
            &:hover{
              color:$ejoy365pc-base-color;
            }
          }
        }
      }
    }
  }
  .minicart {
    display:flex;
    align-items: center;
    width: 130px;
    height:38px;
    border: 1px solid $ejoy365pc-border-color;
    margin-top: 8px;
    padding: 6px 10px;
    color: $ejoy365pc-fourth-color;
    font-size:$font-size-small;
    .shopping {
      position: absolute;
      top: -50%;
      right: -50%;
      transform: translate(-25%,50%);
      width: 17px;
      width:17px;
      line-height: 17px;
      text-align: center;
      color: #fff;
      background: $ejoy365pc-third-color;
      border-radius: 50%;
    }
    .img {
      width: 25px;
      height:25px;
      margin-left: 10px;
      position: relative;
      img{
        width:100%;
        height:100%;
        &.img-after{
          display:none;

        }
      }
    }
    &:hover{
      color:#fff;
      background:$ejoy365pc-base-color;
      border-color:$ejoy365pc-base-color;
      .shopping{
        background: #fff;
        color:$ejoy365pc-base-color;
      }
      .img img{
        &.img-before{
          display:none;
        }
        &.img-after{
          display:block;
        }
      }
    }
  }
  &.row2-fixed{
    background:#fff;
    border-bottom:1px solid $ejoy365pc-base-color;
    width: 100%;
    display: flex;
    justify-content: center;
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:999;
  }
  .category-box {
    position: fixed;
    z-index: 10;
    width: 461px;
    border-radius: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    /*white-space: nowrap;*/
    top: 106px;
  }
}
</style>
