/* eslint-disable no-useless-return */
import http from '@/service/http';
import api from '../src/service/api';
import storage from '../src/service/storage';
import message from '../src/service/message';

export default {
    // 获得登录路由
    needLoginUrls: [
        '/pages/item/favorite-list/index',
        '/pages/activity/kill/details',
        '/pages/activity/super-value/detail',
        '/pages/activity/super-value/order/list',
        '/pages/activity/lottery/index',
        '/pages/distribution/index',
        '/pages/distribution/apply/index',
        '/pages/distribution/user-center/index',
        '/pages/aftermarket/list/index',
        '/pages/user/vip/classes/index',
        '/pages/activity/points/details',
	    '/pages/activity/points/list',
        '/pages/activity/old-belt-new'
    ],
    needWechatLogin: true,
    // 添加路由控制
    async routerFilter(to, from, next) {
        // 记录站外引流
        this.markDrainageData(to);

        // 外链赚客来源
        if (to && to.params && to.params.drp_seller_id) {
            uni.setStorageSync('drpSellerId', to.params.drp_seller_id);
        }
        if (from.params.drp_seller_id && !to.page) {
            uni.setStorageSync('drpSellerId', from.params.drp_seller_id);
        }

        // 分公司来源
        if (to && to.params && to.params.source) {
            uni.setStorageSync('source', to.params.source);
        }
        if (from.params.source && !to.page) {
            uni.setStorageSync('source', from.params.source);
        }
        // #ifdef H5
        var userInfo = null;
        var timestamp = Date.parse(new Date()) / 1000;
        if (to && to.params && to.params.appid && to.params.mobile && to.params.timestamp && to.params.sign) {
            userInfo = await this.getThirdUser(to.params.appid, to.params.mobile, to.params.timestamp, to.params.sign, 'default', '默认对接', from.params.code);
        }

        if (to && to.query && to.query.appid && to.query.mobile && to.query.timestamp && to.query.sign) {
            userInfo = await this.getThirdUser(to.query.appid, to.query.mobile, to.query.timestamp, to.query.sign, 'default', '默认对接', to.query.code);
        }

        // 北京分公司对接
        if (from.params.account && from.params.from === 'bjsh' && from.params.userId && !to.page) {
            userInfo = await this.getThirdUser(from.params.account, from.params.from, timestamp, from.params.userId, 'bj', '北京分公司');
        }

        // 甘肃分公司对接
        if (from.params.gsshuserid && !to.page) {
            userInfo = await this.getThirdUser(from.params.gsshuserid, from.params.gsshuserid, timestamp, from.params.gsshuserid, 'gs', '甘肃分公司');
        }

        // 广东分公司对接
        if (from.params.jygduserid && !to.page) {
            userInfo = await this.getThirdUser(from.params.jygduserid, from.params.jygduserid, timestamp, from.params.jygduserid, 'gd', '广东分公司');
            // 广东分公司缓存来源
            uni.setStorageSync('isGdThird', '1');
        }

        // 江苏分公司对接
        if (from.params.openid && !to.page) {
            userInfo = await this.getThirdUser(from.params.openid, from.params.openid, timestamp, from.params.openid, 'js', '江苏分公司');
        }

        // 浙江分公司对接
        if (from.params.mobile && !to.page && from.params.regDate) {
            userInfo = await this.getThirdUser(from.params.mobile, from.params.regDate, timestamp, from.params.mobile, 'zj', '浙江分公司');
        }

        // 江苏新华易捷分公司对接
        if (from.params.uid && !to.page && from.params.AppId === 'xhyj' && from.params.sign) {
            userInfo = await this.getThirdUser(from.params.uid, from.params.uid, timestamp, from.params.sign, 'jsxhyj', '江苏新华易捷分公司');
        }

        // 总部发劵免登录对接
        if (from.params.zbtoken && !to.page) {
            userInfo = await this.getThirdUser(from.params.zbtoken, from.params.zbtoken, timestamp, from.params.zbtoken, 'zbfj', '总部发劵');
        }

        // 省厅免登录对接
        if (from.params.UserId && !to.page && from.params.AppId === 'gdgat' && from.params.Signed && from.params.Url && from.params.Timestamp) {
            userInfo = await this.getThirdUser(from.params.UserId, from.params.Url, from.params.Timestamp, from.params.Signed, 'proff', '省厅对接');
        }

        // 网厅分公司对接
        if (from.params.token && !to.page && from.params.token.match(RegExp(/sso/))) {
            userInfo = await this.getThirdUser(from.params.token, from.params.token, timestamp, from.params.token, 'snpls', '网厅分公司');
            // 网厅分公司缓存来源
            uni.setStorageSync('isSnplsThird', '1');
        }

        // 网厅分公司对接：加油中石化app中IOS平台微信支付回来--默认会打开safari浏览器，需调起加油中石化APP
        if (from && from.params && from.params.isSnplsThirdWechat) {
            window.location.href = 'sinopecsales://m.sinopecsales.com?appRedirectUrl=' + encodeURIComponent(uni.baseUrl + 'wap/#/pages/order/list/index?pay=1');
        }

        // 南网分公司对接
        if (from.params.ejoy_source === 'xfnw' && !to.page && from.params.code) {
            userInfo = await this.getThirdUser(from.params.code, from.params.code, timestamp, from.params.code, 'xfnw', '幸福南网');
        }
        
        // 和生活对接
        if (from.params.sticket && from.params.scode && from.params.sourceid && !to.page) {
            userInfo = await this.getThirdUser(from.params.sticket, from.params.sourceid, timestamp, from.params.scode, 'hsh', '和生活');
        }

        // 粤警工会免登对接
        if (from.params.Timestamp && from.params.UserId && from.params.Signed && !to.page) {
            userInfo = await this.getThirdUser(from.params.UserId, from.params.Timestamp, from.params.Signed, from.params.Signed, 'yjgh', '粤警工会');
        }

        if (userInfo !== null) {
            // this.loginSuccessEvent();
            // if (userInfo && userInfo.newYorker) {
            //     uni.setStorageSync('newGuest', '1');
            // }
            return false;
        }
        // #endif
        return true;
    },
    // 登录成功事件处理
    async loginSuccessEvent() {
        // 更新本地购物车数据到数据库
        let localCartList = api.getNoLoginCartList();
        if (localCartList.length > 0) {
            await api.httpPost('api/cart/add_no_login_list', {
                cartList: localCartList
            });
            api.deleteAllNoLoginCart();
        }
        // 获取购物车数量
        let cartNum = await api.httpGet('/api/cart/count');
        api.setCartNum(cartNum);
        let condition = {
            limit: 9999
        };
        let favoriteList = await api.httpGet('/api/member/get_favorite_list', condition);
        api.setFavoriteList(favoriteList.results);
    },
    // 第三方免登
    async getThirdUser(appid, mobile, timestamp, sign, type, typename, memcCode) {
        let url = api.getLoginFromPaht();
        if (api.getUser() != null && type !== 'zbfj' && type !== 'yjhg') {
            api.to(url);
            return null;
        }
        try {
            let response = await http.httpPost('api/thirdparty/login_by_thirdParty', {
                appId: appid,
                mobile,
                timestamp,
                sign,
                type,
                typename,
                memcCode
            });
            if (response.userDto) {
                storage.setUser(response.userDto);
                // 如果用户免等陆，将用户信息发送易观
                api.ygAliasEvent(response.userDto.id + '');
                this.loginSuccessEvent();

                api.to(url);
                location.reload();
                // return response.userDto;
            }
        } catch (e) {
            console.error('third login failure', e);
            message.messageError(e.message);
            // api.toastError(e.message);
            return null;
        }
    },
    // 记录站外引流
    markDrainageData(to) {
    let pages = to.query;
    let user = api.getUser();

    let drainageData = {
        type: pages.source,
        code: null,
        priority: null,
        expiryTime: null
    };
    if (drainageData.type === 'linkstars') {
        drainageData.code = pages.feedback;
        drainageData.priority = 1;
        // 30 days
        drainageData.expiryTime = Date.now() + 1000 * 60 * 60 * 24 * 30;
    } else if (drainageData.type === 'offsite') {
        drainageData.code = pages.code;
        drainageData.priority = 2;
    } else {
        return;
    }
    api.httpPost('api/drainage_record/create', {
        'drainageBaseId': drainageData.id,
        'drainageType': drainageData.type,
        'memberId': !user ? null : user.id
    });
    let drainageDataLocal = api.getDrainageOffsite();
    let result = [];
    if (drainageDataLocal) {
        drainageDataLocal.forEach(e => {
            if (e.type !== drainageData.type) {
                result.push(e);
            }
        });
    }
    result.push(drainageData);
    api.setUpDrainageOffsite(JSON.stringify(result));
    }
};
