<template>
    <div class="header-row3" ref="navList">
        <el-row type="flex" class="wrap">
            <el-col :span="4">
                <div class="category-nav" :class="{hover: activeIndex>=0}"
                     @mouseover="activeIndex=99" @mouseleave="activeIndex=-1">
                    <div class="all-category">所有商品分类</div>
                    <ul class="category-box"
                        :style="{visibility:expandCategory?'visible':'hidden'}">
                        <li v-for="(item1,index) in categories" :key="item1.id"
                            class="cat-item-lv1" :class="{hover: activeIndex===index}"
                            @mouseover.stop="onMouseOver(index)">
                            <div class="cat-lv1-title">
                                <i :class="icons[index]"></i>
                                <!-- 一级分类 -->
                                <router-link :to="{path: '/goods/list', query: { categoryPath: item1.catPath  }}">
                                    {{item1.categoryName}}
                                </router-link>

                            </div>
                            <div class="subitems">
                                <dl v-for="item2 in item1.children" :key="item2.id"
                                    class="cat-item-lv2">
                                    <dt>
                                        <!-- 二级分类 -->
                                        <router-link :to="{path: '/goods/list', query: { categoryPath: item2.catPath }}">
                                            {{item2.categoryName}}
                                        </router-link>
                                    </dt>
                                    <dd>
                                        <em v-for="item3 in item2.children" :key="item3.id"
                                            class="cat-item-lv3">
                                             <!-- 三级分类 -->
                                            <router-link class="third-category"  :to="{path: '/goods/list', query: { cateId: item3.id, categoryPath: item3.catPath }}">
                                                {{item3.categoryName}}
                                            </router-link>
                                        </em>
                                    </dd>
                                </dl>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="20">
                <ul class="menu">
                    <li class="nav-item" :class="activeClass('/')">
                        <router-link to="/">首页</router-link>
                    </li>
                    <li v-for="(item,index) in menus" :key="index"
                        class="nav-item" :class="activeClass(item.url)">
                        <a @click="toActivityPage(item.url)" :target="item.newPage?'_blank':''">
                            {{item.menuName}}
                        </a>
                    </li>
                </ul>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import bus from '@/service/bus.js';
    export default {
        data() {
            return {
                expandCategory: false,
                activeIndex: -1,
                categories: [],
                icons: [
                    'el-icon-ice-tea',
                    'el-icon-box',
                    'el-icon-scissors',
                    'el-icon-umbrella',
                    'el-icon-suitcase',
                    'el-icon-mobile-phone',
                    'el-icon-truck',
                    'el-icon-lollipop',
                    'el-icon-watch',
                    'el-icon-present',
                    'el-icon-mobile-phone',
                    'el-icon-bicycle',
                    'el-icon-truck',
                    'el-icon-basketball',
                    'el-icon-food',
                    'el-icon-cold-drink',
                    'el-icon-pear',
                    'el-icon-mic',
                    'el-icon-toilet-paper',
                    'el-icon-reading'

                ],
                menus: []
            };
        },
        computed: {
            searchScrollTop() {
                let offsetTop = this.$refs.navList.offsetTop;
                return offsetTop;
            }
        },
        async mounted() {
            // 只有首页和商品列表页商品分类才展开
            let currentRouter = this.$router.currentRoute;
            if (currentRouter && (currentRouter.name === 'home' || currentRouter.name === 'itemList')) {
                this.expandCategory = true;
            }
            let categories = await this.$api.httpGet('api/category/list');
            this.categories = categories.filter(function (value, index, array) {
                return value.categoryName !== '付费会员';
            });
            this.menus = await this.$api.httpGet('api/home/pc_nav_menus');
            bus.$emit('searchScrollTop', this.searchScrollTop);
        },
        
        methods: {
            onMouseOver(index) {
                this.activeIndex = index;
            },
            activeClass (url) {
                let currentRouter = this.$router.currentRoute;
                if (currentRouter && url === currentRouter.path) {
                    return 'active';
                }
            },
            toActivityPage(suffixUrl) {
                suffixUrl = suffixUrl[0] === '/' ? suffixUrl : '/' + suffixUrl;
                window.location.href = window.location.href.split('pc')[0] + 'pc' + suffixUrl.split(' ').join('');
                window.location.reload();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header-row3 {
        background: #f5f5f5;
        width: 100%;
        height: 40px;
        overflow: visible;

        .category-nav {
            width: 218px;
            color: #fff;
            position: relative;

            .all-category {
                position: relative;
                z-index: 2;
                background: #312b30;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                overflow: hidden;
                border-bottom: 1px #4b464a solid;
            }

            &.hover {
                .category-box {
                    visibility: visible !important;
                }
            }

            .category-box {
                position: absolute;
                z-index: 10;
                min-width: 100%;
                white-space: nowrap;
                top: 40px;

                .cat-item-lv1 {
                    padding-left: 6px;
                    font-size: 14px;
                    height: 41px;
                    border-left: 3px solid transparent;
                    border-bottom: 1px #4b464a solid;
                    background: rgba(15, 15, 15, 0.82);

                    &.hover {
                        background: #555;
                        border-left: 3px solid #f39800;

                        .subitems {
                            visibility: visible !important;
                        }
                    }

                    .cat-lv1-title {
                        position: relative;
                        padding: 10px 0 0 40px;

                        a {
                            color: #fff;
                            font-size: 16px;
                        }

                        i {
                            font-size: 16px;
                            padding-right: 10px;
                        }
                    }

                    .subitems {
                        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
                        visibility: hidden;
                        position: absolute;
                        z-index: 20;
                        top: 0;
                        left: 218px;
                        height: 369px;
                        width: 992px; //1210-218
                        padding: 0 0 0 20px;
                        overflow-y: auto;
                        background: #fff;
						display: flex;
						flex-direction: column;	
                        .cat-item-lv2 {
                            padding: 5px 0;
                            font-size: 13px;

                            dt {
                                float: left;
                                width: 87px;

                                a {
                                    font-weight: bold;
                                }
                            }

                            dd {
                                float: left;
                                width: 758px;
                                border-bottom: 1px solid #eee;
                                padding: 0 0 10px;
								line-height: 1.5rem;
                                em {
                                    float: left;
                                    font-style: normal;
                                    .third-category{
                                        border-left: 1px solid darkgray;
                                        padding: 0 10px 0 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu {
            margin-left: 20px;
            max-width: 992px;
            display:flex;
            justify-content: space-between;

            li {
                margin: 0 10px;
                padding: 0 20px;
                font-size: 14px;
                line-height: 38px;
                display: inline-block;
            }

            li.active {
                border-bottom: 2px solid #f39800;
            }

            li.active a {
                color: #f39800;
            }
        }
    }
</style>
