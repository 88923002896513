<template>
  <div class="footer">
    <guide></guide>
    <help></help>
  </div>
</template>

<script>
import guide from './footer/guide';
import help from './footer/help';
export default {
  components: {
    guide,
    help
  }
};
</script>

<style scoped>
.footer {
  margin-top: 20px;
}
</style>
